import axios from "axios"

// ログイン
export const login = {
    login(param) {
        return axios({
            method: 'POST',
            url: '/login',
            params: param
        })
    }
}

// GPTとのチャット
export const chat = {
    chatWithGPT(param) {
        return axios({
            method: 'POST',
            url: '/api/chat',
            params: param,
            headers: {
                'Authorization': `Bearer ${process.env.OPENAI_API_KEY}`
            }
        })
    }
}

// ユーザ登録
export const register = {
    register(param) {
        return axios({
            method: 'POST',
            url: '/register/register',
            params: param
        })
    },
}
// home
export const home = {
    load(param) {
        return axios({
            method: 'GET',
            url: '/home/load',
            params: param
        })
    },
}

// 体重記録
export const weight = {
    record(param) {
        return axios({
            method: 'GET',
            url: '/weight/record',
            params: param
        })
    },
}

// 目標管理
export const goal = {
    set(param) {
        return axios({
            method: 'GET',
            url: '/goal/set',
            params: param
        })
    },
}
