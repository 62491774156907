<template>
<!-- eslint-disable -->
	<div class="mt-4">
		<h1>目標</h1>
        <div v-for="(question, index) in questions" :key="index" class="mt-8">
          <div v-if="currentQuestionIndex === index">
            <div v-if="index === 0">
              <h1 class="text-center">{{ weight.toFixed(1) }} kg</h1>
              <v-row class="my-12">
                <v-spacer></v-spacer>
                <v-btn class="mx-4" @click="minus()">
                  <v-icon size=25>mdi-minus</v-icon>
                </v-btn>
                <v-btn style="display: block; width: 150px;" type="submit" color="primary" @click="setWeight">
                  <v-icon size=20 class="pr-2">mdi-pencil</v-icon>記録
                </v-btn>
                <v-btn class="mx-4" @click="plus()">
                  <v-icon size=25>mdi-plus</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </div>

            <div v-else>
              <h2 class="mb-4">{{ question.text }}</h2>
              <v-btn
                v-for="(option, optionIndex) in question.options"
                :key="optionIndex"
                color="primary"
                outlined
                class="mr-2 my-2"
                @click="answerQuestion(optionIndex)"
              >
                {{ option }}
              </v-btn>
            </div>
          </div>
        </div>
	</div>
</template>

<script>
/*eslint-disable */
import { chat } from '@/api.js';
import * as api from '@/api.js'
import DatePicker from '@/components/DatePicker.vue'
export default {
    name: 'Goal',
    components: { DatePicker },
    data() {
        return {
            weight: 60.0,
            currentQuestionIndex: 0,
            questions: [
              {},
              {
                  text: 'トレーニングの目的は？',
                  options: ['減量', '維持', '増量'],
              },
              {
                  text: 'ジムに行く頻度は？',
                  options: ['自宅のみ', '週1から週2', 'それ以上'],
              },
              {
                  text: '日常生活の運動量は？',
                  options: ['いわゆる運動不足というやつ。', '仕事は座りが多いけどがんばってる！', '運動不足？なにそれ、美味しいの？'],
              },
            ],
            prompt: 'You are fitness trainer. 以下の条件をもとに日カロリーを算出。４文字で"結果のみ"出力 '
        };
    },
    methods: {
        minus() {
            this.weight -= 0.1;
        },
        plus() {
            this.weight += 0.1;
        },
        setWeight() {
            this.prompt += ' ・Goal Weight:' + this.weight
            this.nextQuestion();
        },
        nextQuestion() {
            this.currentQuestionIndex++;
        },
        answerQuestion(optionIndex) {
            if(this.currentQuestionIndex == 1){
              let term1;
              switch(optionIndex){
                case 0:
                  term1 = ' ・cutting '
                  break
                case 1:
                  term1 = ' ・keep in shape '
                  break
                case 2:
                  term1 = ' ・bulking '
                  break
              }
              this.prompt += term1
              this.nextQuestion();
              return
            }
            if(this.currentQuestionIndex == 2){
              let term2;
              switch(optionIndex){
                case 0:
                  term2 = '・0 gym days per week '
                  break
                case 1:
                  term2 = '・2 gym days per week '
                  break
                case 2:
                  term2 = '・more than 2 gym days per week '
                  break
              }
              this.prompt += term2
              this.nextQuestion();
              return
            }
            if(this.currentQuestionIndex == 3){
              let term3;
              switch(optionIndex){
                case 0:
                  term3 = '・sedentary '
                  break
                case 1:
                  term3 = '・lightly active '
                  break
                case 2:
                  term3 = '・very active '
                  break
              }
              this.prompt += term3
              this.getReply()
            }
        },
        async getReply(attempts = 0) {
          if (this.prompt.length > 200) {
            alert('Prompt is too long. Please limit your input to 200 characters.');
            return;
          }
          if (attempts >= 3) {
            alert('ただいま混み合っております。時間をあけてお試しくださいませ。');
            return;
          }
          const res = await chat.chatWithGPT({ prompt: this.prompt });
          if (res.status === 200) {
            const goal = res.data.content
            if(goal.length < 5){
              this.setGoal(goal)
              this.$store.dispatch('setGoal', {goal: goal})
            } else {
              this.getReply(attempts + 1);
            }
          } else {
            // Error handling
            console.error('Failed to chat with GPT:', res);
          }
        },
        setGoal(goal){
          api.goal.set({userId: this.$store.getters.loginInfo.userId, goal: goal}).then((res) => {
            switch(res.status) {
              case 200:
						    this.pop('', res.data)
                break
              case 500:
                this.errorFlg = true
                this.pop(res.data.error[0])
                break
            }
          }).finally()
        }
    },
    created(){
      this.prompt += '・height:' + this.$store.getters.loginInfo.height + ' ・age:' + this.$store.getters.loginInfo.age + '・current weight:' + this.$store.getters.loginInfo.weight
    },

};
</script>
<style>
.v-menu__content {
    left: 42px!important;
}
.v-btn__content{
	padding-right: 4px;
}
</style>